import React, { useEffect, useState } from 'react';

import { useSelector } from 'react-redux';

import { useDevice } from '@bonnet/next/device';

import { BrowserStorage } from '@atc/browser-storage';
import { getPixallId } from '@atc/pixall-fns';

import {
    Button,
    Image,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    ModalTitle,
    Text,
} from 'reaxl';
import { sendClick, sendImpressions } from 'reaxl-analytics';
import { useFeatures } from 'reaxl-features';

import useFcmToken from '@/utilities/firebase/useFcmToken';

import { srpFiltersDuck } from '@/ducks/srp';

import ConsumerDeviceFetcher from '@/fetchers/ConsumerDeviceFetcher';

const browserNotificationPromptCache = new BrowserStorage('OFFSITE_BROWSER_NOTIFS_PROMPT', {
    persist: true,
});

const recentInventoryCache = new BrowserStorage('ATC_RECENT_INVENTORY', {
    persist: true,
});

const staticContentEndpoint = '/content/static/img/icon';

function IosInstallPrompt({ showIOSPrompt, dismissIOSPrompt }) {
    return (
        <Modal
            bsSize="small"
            onHide={dismissIOSPrompt}
            show={showIOSPrompt}
        >
            <ModalHeader closeButton>
                <ModalTitle>Device Notification</ModalTitle>
            </ModalHeader>
            <ModalBody className="text-center margin-vertical-4 padding-horizontal-5">
                <Text
                    className="margin-horizontal-5"
                    color="primary"
                    componentClass="div"
                    weight="bold"
                    size={500}
                >
                    One More Step
                </Text>
                <Text
                    className="margin-horizontal-auto margin-vertical-3"
                    componentClass="div"
                    style={{ width: '90%' }}
                    size={300}
                >
                    Add this page to your home screen to get device alerts.
                </Text>
                <Text
                    className="margin-horizontal-auto margin-vertical-2"
                    color="primary"
                    componentClass="div"
                    weight="bold"
                    size={200}
                >
                    Just tap
                    <Image
                        className="margin-horizontal-2"
                        src={`${staticContentEndpoint}/device/iOS-install.svg`}
                        height={20}
                        width={20}
                    />
                    below then &apos;Add to Home Screen&apos;
                </Text>
            </ModalBody>
        </Modal>
    );
}

function OffsiteNotificationsPrompt() {

    const { os } = useDevice();
    const isIOS = os === 'ios';

    const {
        offsite_browser_notifications: [, { prompt_version: promptVersion }],
    } = useFeatures(['offsite_browser_notifications']);

    const { subscribeToNotifications } = useFcmToken();

    const [showPrompt, setShowPrompt] = useState(false);
    const [showIOSPrompt, setShowIOSPrompt] = useState(false);

    const dismissIOSPrompt = () => setShowIOSPrompt(false);

    const modelFilterValues = useSelector(srpFiltersDuck.selectors.getModelFilterValues);

    const bodyStyleValues = useSelector(srpFiltersDuck.selectors.getBodyStyleValues);

    const alertVersion = promptVersion === 'alert';
    const dreamCarVersion = promptVersion === 'dream-car';
    const priceDropVersion = promptVersion === 'price-drop';

    const getHeader = () => {
        if (alertVersion) {
            return 'Get shopping notifications on your device!';
        }
        if (dreamCarVersion) {
            return 'Don\'t miss out on your dream car!';
        }

        if (priceDropVersion) {
            return 'Get price drops and new listings!';
        }

        return '';
    };

    const imgHeight = (dreamCarVersion || priceDropVersion) ? 100 : 120;
    const imgWidth = (alertVersion || dreamCarVersion) ? 175 : 100;

    const browserNotifsPrompted = browserNotificationPromptCache.getItem()?.srpPrompted;

    const dismissPrompt = () => {
        setShowPrompt(false);
        browserNotificationPromptCache.setItem({ srpPrompted: true });
    };

    const onGetNotificationsClick = async () => {
        const { success, fcmToken } = await subscribeToNotifications();
        if (success) {
            dismissPrompt();

            ConsumerDeviceFetcher({ brand: 'ATC', fcmToken, pixallId: getPixallId() });

            // NOTE: If the user is on an iOS device, an installation step is needed for notifications to work.
            // This alerts users to add the page to their home screen.
            if (isIOS) {
                setShowIOSPrompt(true);
            }
        }
        sendClick('offsiteBrowserPushNotificationClick', {}, { location: 'srp' });

        dismissPrompt();
    };

    const recentlyViewedVdps = recentInventoryCache.getItem() || [];

    const oneDay = 24 * 60 * 60 * 1000;
    const today = Date.now();

    const mostRecentTimestamp = recentlyViewedVdps[0]?.timestamp ?? 0;
    const hasVdpEngagement = recentlyViewedVdps.length >= 1 && ((today - mostRecentTimestamp) <= oneDay);

    const sendNotificationImpression = () => sendImpressions({ name: 'offsiteBrowserPushNotificationImpression', data: { location: 'srp' } });

    // turn on prompt after 1 vdp view and have selected one model or body style
    useEffect(() => {
        if (!browserNotifsPrompted) {
            if (hasVdpEngagement && (Object.keys(modelFilterValues).length > 0 || Object.keys(bodyStyleValues).length > 0)) {
                setShowPrompt(true);
                sendNotificationImpression();
            }
        }
    }, [bodyStyleValues, browserNotifsPrompted, hasVdpEngagement, modelFilterValues]);

    return (
        <>
            {isIOS && (
                <IosInstallPrompt
                    showIOSPrompt={showIOSPrompt}
                    dismissIOSPrompt={dismissIOSPrompt}
                />
            )}

            <Modal
                bsSize="small"
                onHide={dismissPrompt}
                show={showPrompt}
            >
                <ModalHeader closeButton>
                    <ModalTitle>Notifications</ModalTitle>
                </ModalHeader>
                <ModalBody className="text-center margin-vertical-4 padding-horizontal-5">
                    <Text
                        className="margin-horizontal-5"
                        color="primary"
                        componentClass="div"
                        weight="bold"
                        size={500}
                    >
                        {getHeader()}
                    </Text>
                    <Image
                        className="margin-vertical-5"
                        src={`${staticContentEndpoint}/notifications/${promptVersion}.svg`}
                        height={imgHeight}
                        width={imgWidth}
                    />
                    <Text
                        className="margin-auto"
                        componentClass="div"
                        style={{ width: '80%' }}
                    >
                        Receive price drop alerts and newly listed search results on this device.
                    </Text>
                </ModalBody>
                <ModalFooter>
                    <Button
                        bsStyle="text"
                        className="margin-right-4"
                        onClick={dismissPrompt}
                    >
                        No Thanks
                    </Button>
                    <Button
                        bsStyle="primary"
                        onClick={onGetNotificationsClick}
                    >
                        Get Notifications
                    </Button>
                </ModalFooter>
            </Modal>
        </>
    );
}

export default OffsiteNotificationsPrompt;
