import React, { memo } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import dynamic from 'next/dynamic';

import _get from 'lodash/get';
import _isEqual from 'lodash/isEqual';

import { useDevice } from '@bonnet/next/device';

import { Button, Glyphicon } from 'reaxl';
import { useFeatures } from 'reaxl-features';

import { userPreferencesDuck } from '@/ducks';

import {
    srpFiltersDuck,
} from '@/ducks/srp';

// containers

import CompareDrawerPlaceholder from '@/components/CompareDrawerPlaceholder';
import LazyComponent from '@/components/LazyComponent';

import FilterMobileControlsContainer from './FilterMobileControlsContainer';
import FilterSortDropdownContainer from './FilterSortDropdownContainer';
import MyWalletIconButton from './MyWalletIconButton';

const CompareListingsDrawer = dynamic(() => import(
    /* webpackChunkName: "CompareListingsDrawer" */
    '@/components/CompareListingsDrawer'
), {
    loading: () => (
        <CompareDrawerPlaceholder />
    ),
    ssr: false,
});

function SearchResultsPageTopActionsContainer({
    onFilterClick = () => { },
}) {
    const {
        compare: [enableCompare],
        my_wallet: [enableMyWallet],
        remove_payments_toggle: [removePaymentsToggle],
    } = useFeatures([
        'compare',
        'my_wallet',
        'remove_payments_toggle',
    ]);

    const dispatch = useDispatch();
    const device = useDevice();
    const isXs = _get(device, 'is.xs', false);

    const filters = useSelector(srpFiltersDuck.selectors.getSelectedFilterChips, _isEqual);
    const filterCount = filters.length;

    const showDrawer = useSelector(userPreferencesDuck.selectors.getShowDrawer);
    const showFilterCount = filterCount && (filterCount > 0) ? ` (${filterCount})` : '';

    const handleFilterClick = () => {
        onFilterClick();
        dispatch(srpFiltersDuck.creators.showFilters());
    };

    if (!isXs) {
        return (
            <>
                {showDrawer && enableCompare && (
                    <LazyComponent
                        fallback={<CompareDrawerPlaceholder />}
                        key="compare-drawer-top-placeholder"
                    >
                        <CompareListingsDrawer
                            isCollapsible
                            parent="top"
                        />
                    </LazyComponent>
                )}
                <div
                    className="row display-flex"
                    data-cmp="cntnr-pg-num"
                >
                    <div className="padding-horizontal-2">
                        <Button
                            className="display-md-none"
                            onClick={handleFilterClick}
                        >
                            <Glyphicon
                                glyph="filter"
                                className="margin-right-1 text-accent"
                            />
                            Filter
                            {showFilterCount}
                        </Button>
                    </div>
                    <div className="display-flex margin-left-auto padding-horizontal-2">
                        {(enableMyWallet && !removePaymentsToggle) && (
                            <div className="display-flex flex-nowrap margin-right-5 align-items-center margin-left-4">
                                <MyWalletIconButton />
                            </div>
                        )}

                        <FilterSortDropdownContainer />
                    </div>
                </div>
            </>
        );
    }

    return (
        <div
            className="row margin-bottom-4"
            data-cmp="cntnr-pg-num-top"
        >
            <FilterMobileControlsContainer />
        </div>
    );
}

export default memo(SearchResultsPageTopActionsContainer, _isEqual);
