import React, { memo, useCallback, useEffect, useMemo } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import interactionResponse from 'await-interaction-response';
import clsx from 'clsx';
import _get from 'lodash/get';
import _set from 'lodash/set';
import _throttle from 'lodash/throttle';

import { useDevice } from '@bonnet/next/device';

import { pageNames } from '@atc/bonnet-paths';
import { ClientOnly } from '@atc/react-client-only';
import { useMutationObserver } from '@atc/use-mutation-observer';
import { waitUntil } from '@atc/wait-until';

import { CloseButton } from 'reaxl';
import { useMessageEvent } from 'reaxl-ads';
import { sendClick } from 'reaxl-analytics';
import { closeStickyAdClick } from 'reaxl-analytics-handlers';
import { useBrand } from 'reaxl-brand';
import { useFeatures } from 'reaxl-features';

import refreshFixedAd from '@/utilities/refreshFixedAd';

import { srpAdsDuck, srpFixedAdDuck, srpResultsDuck } from '@/ducks/srp';

import AtcAdSlot from '@/containers/AtcAdSlot';
import DisableAdsFeatureFlag from '@/containers/global/DisableAdsFeatureFlag';

const { SEARCH_RESULTS } = pageNames;

// ad sizes
const adSize320x50 = [[320, 50], [300, 50]];

const positionDefaultSizes = [[728, 90]];

// ensure ad sizes are in order from shortest to tallest to make sure min size is smallest height
const positionDefaultSizesOnDesktop = [[728, 90], [970, 90]];

const fixedAdSizes = {
    top: { default: positionDefaultSizes, lg: positionDefaultSizesOnDesktop, xs: adSize320x50 },
};

const isPubadsReady = () => window && window.googletag && window.googletag.apiReady && window.googletag.pubadsReady && typeof window.googletag.pubads === 'function';

/* find & refresh the fixed ad every 30 seconds up to 25 times */
function useAutoRefreshTimer({ intervalValue, refreshLimitValue, showFixedAd, enableFixedAds, closeFixedAd, overlayOpen, slotId, scrolled, dispatch, refreshCount }) {
    const optInterval = intervalValue && Number(intervalValue);
    const interval = (optInterval) || 30000;

    const optRefreshLimit = refreshLimitValue && Number(refreshLimitValue);
    const refreshLimit = (optRefreshLimit) || 25;

    useEffect(() => {
        let fixedAdInterval = null;
        if (enableFixedAds) {
            waitUntil(
                isPubadsReady,
                () => {
                    if (showFixedAd && !closeFixedAd && !overlayOpen && scrolled) {
                        fixedAdInterval = setInterval(() => {
                            refreshFixedAd({ dispatch, refreshCount, slotId });
                        }, interval);
                    }

                    if ((!showFixedAd && refreshCount > 1) || refreshCount >= refreshLimit || closeFixedAd || overlayOpen) {
                        clearInterval(fixedAdInterval);
                    }

                },
                300,
                10000,
                // eslint-disable-next-line no-console
                () => { console.error('Fixed Ad Failed to load'); },
            );
        }
        return () => (enableFixedAds && clearInterval(fixedAdInterval));
    }, [refreshLimit, interval, showFixedAd, enableFixedAds, closeFixedAd, overlayOpen, slotId, scrolled, dispatch, refreshCount]);
}

function FixedAdContainer({
    className,
}) {
    const {
        ads: [enableAds],
        fixed_ads: [enableFixedAds, { slot_id: slotId }],
        srp_fixed_ad_config: [, {
            disable: suppressFixedAd = false,
            interval: intervalValue = 30000,
            refreshLimit: refreshLimitValue = 25,
            scrollPercentage: scrollPercentTrigger = 10,
        }],
    } = useFeatures([
        'ads',
        'fixed_ads',
        'srp_fixed_ad_config',
    ]);

    const cmpId = 'fixedAdContainer';

    const closeFixedAd = useSelector(srpFixedAdDuck.selectors.getCloseFixedAd);
    const showFixedAd = useSelector(srpFixedAdDuck.selectors.getShowFixedAd);
    const scrolled = useSelector(srpFixedAdDuck.selectors.getScrolled);
    const refreshCount = useSelector(srpFixedAdDuck.selectors.getRefreshCount);
    const hasResults = useSelector(srpResultsDuck.selectors.hasActiveResults);
    const { brands, isBrand } = useBrand();
    const isKbbBranded = isBrand(brands.KBB_BRAND);
    const dispatch = useDispatch();
    const device = useDevice();

    const isXs = _get(device, 'is.xs', false);
    const isLg = _get(device, 'is.lg', false);

    const overlayOpen = useMutationObserver(cmpId, 'style');

    const getScrollPercent = useMemo(() => _throttle(() => {
        const h = document.documentElement;
        const b = document.body;
        const st = 'scrollTop';
        const sh = 'scrollHeight';
        const currentScrollPercent = (h[st] || b[st]) / ((h[sh] || b[sh]) - h.clientHeight) * 100;
        if (currentScrollPercent > scrollPercentTrigger && !scrolled) {
            dispatch(srpFixedAdDuck.creators.setKeys({
                scrolled: true,
            }));
            window.removeEventListener('scroll', getScrollPercent);
        }
        // Disable exhaustive dependencies so that we only create one scroll listener on the window and not multiple.
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, 100, { leading: true }), []);

    // reset refresh count when SRP is visited/re-visited
    useEffect(() => {
        dispatch(srpFixedAdDuck.creators.setFixedAdRefresh({ refreshCount: 1 }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        // eslint-disable-next-line github/prefer-observers
        window.addEventListener('scroll', getScrollPercent);

        return () => window.removeEventListener('scroll', getScrollPercent);
    }, [getScrollPercent]);

    useEffect(() => () => {
        // we need to manually remove this from pubads on unmount so that any callbacks for bids/post ad render don't error
        if (isPubadsReady()) {
            const slot = window.googletag.pubads().getSlots().find((x) => x.getSlotId().getDomId() === 'div-gpt-ad-' + slotId);
            if (slot) {
                window.googletag.pubads().clear([slot]);
            }
        }
    }, [slotId]);

    const handleStickyAdClose = useCallback(async (event) => {
        const eventTarget = _get(event, 'target.value', null);
        await interactionResponse();
        _set(event, 'target.value', eventTarget);

        sendClick(closeStickyAdClick, event);
        dispatch(srpFixedAdDuck.creators.setKeys({
            closeFixedAd: true,
        }));
    }, [dispatch]);

    const adPos = 'top';

    const sizes = useMemo(() => {
        if (isXs) {
            return _get(fixedAdSizes, [adPos, 'xs']);
        }

        if (isLg) {
            return _get(fixedAdSizes, [adPos, 'lg']);
        }
        return _get(fixedAdSizes, [adPos, 'default']);
    }, [isXs, adPos, isLg]);

    const adContainerHeight = isXs ? 50 : 90;
    const adContainerWidth = isXs ? 320 : 728;

    const targeting = {
        pos: adPos,
        prd: 'rc12',
        active_browser: 'y',
        refresh: 1,
    };

    // on KBB SRP listen for window event that may tell the ad to collapse and hide (but still render the slot)
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const isKbbAdHidden = isKbbBranded ? useMessageEvent(slotId) : false;

    const isAdHidden = !showFixedAd || closeFixedAd || isKbbAdHidden;

    const classNames = clsx(className, {
        'display-flex': true,
        'justify-content-center': true,
        'padding-horizontal-2': true,
        hidden: isAdHidden,
    });

    const style = {
        backgroundColor: 'rgba(255, 255, 255, 0.7)',
        height: adContainerHeight,
        overflow: 'hidden',
        position: 'fixed',
        bottom: 0,
        left: 0,
        width: '100%',
        zIndex: 3,
        visibility: isAdHidden ? 'hidden' : '',
    };

    useAutoRefreshTimer({
        intervalValue,
        refreshLimitValue,
        showFixedAd,
        enableFixedAds,
        closeFixedAd,
        overlayOpen,
        slotId,
        scrolled,
        dispatch,
        refreshCount,
    });

    const renderFixedAd = enableAds && enableFixedAds && !suppressFixedAd && hasResults && scrolled;

    /*
     * See FixedAdWayPointContainer and SearchResultsPageContainer to see logic of when to display & refresh the FixedAd
     * showFixedAd & closedFixedAd are stored in the fixedAd store and controlled by FixedAdWaypointContainer actions
     * do not render ad on mobile until the user scrolls
     */
    return renderFixedAd && (
        <ClientOnly>
            <DisableAdsFeatureFlag>
                <div
                    className={classNames}
                    data-cmp={cmpId}
                    id={cmpId}
                    style={style}
                >
                    <div className="display-flex gap-1 align-items-start">
                        <div style={{ minWidth: adContainerWidth }}>
                            <AtcAdSlot
                                key={slotId + adPos}
                                slotId={slotId}
                                size={sizes}
                                targeting={targeting}
                                className="margin-vertical-0"
                                showLabel={false}
                                showPlaceholder={false}
                                adSelector={srpAdsDuck.selectors.getDuckState}
                                pageName={SEARCH_RESULTS}
                            />
                        </div>
                        <CloseButton
                            onClick={handleStickyAdClose}
                        />
                    </div>
                </div>
            </DisableAdsFeatureFlag>
        </ClientOnly>
    );
}

export default memo(FixedAdContainer);
