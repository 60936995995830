import React, { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import clsx from 'clsx';
import _get from 'lodash/get';

import { getPathSRP } from '@/utilities';

import { useDevice } from '@bonnet/next/device';

import {
    Button,
    Col,
    Glyphicon,
    Heading,
    IconButton,
    Image,
    Link,
    Row,
    Text,
} from 'reaxl';
import { sendClick } from 'reaxl-analytics';
import { psxPrivateSellerExchangeLogoClick } from 'reaxl-analytics-handlers';
import { useBrand } from 'reaxl-brand';
import { useFeatures } from 'reaxl-features';

import remoteActions from '@/actions/srp/remoteActions';

import {
    authDuck,
    queryDuck,
    userDuck,
} from '@/ducks';

import srpContentDuck from '@/ducks/srp/srpContentDuck';

import useSrpNavigation from '@/hooks/useSrpNavigation';

import FilterLocationModalContainer from './FilterLocationModalContainer';
import InventoryClearFilters from './InventoryClearFilters';
import KeywordSearchContainer from './KeywordSearchContainer';
import SaveSearchContainer from './SaveSearchContainer';
import SRPBreadcrumbsContainer from './SRPBreadcrumbsContainer';
import SuggestedFilterContainer from './SuggestedFilterContainer';

const renderHeading = ({ locationHeading }) => (
    <>
        <Heading
            componentClass="h1"
            className="display-inline margin-right-2"
            level={3}
            id="search-results-heading"
        >
            {locationHeading}
        </Heading>
        <FilterLocationModalContainer className="text-link link-undecorated" />
    </>
);

function KeywordButton({ onClick }) {
    return (
        <IconButton
            label="Search by keyword"
            glyph="search"
            hideLabel
            size="medium"
            onClick={onClick}
        />
    );
}

const renderBreadCrumbsAndHeading = (locationHeading, showLink, isXs, path, linkText) => (
    <>
        <SRPBreadcrumbsContainer />
        {showLink && (
            <Link
                href={path ?? 'cars-for-sale/all-cars'}
                className="text-bold margin-top-2"
                undecorated
            >
                {linkText}
            </Link>
        )}
        <div
            className="display-md-flex align-items-md-center margin-top-2"
            style={{ minHeight: isXs ? '54px' : '' }}
        >
            {renderHeading({ locationHeading })}
        </div>
    </>
);

function SrpHeroContainer({
    dataCmp,
    heroSrc,
    altText,
    isXs,
    imgHeight,
    linkText,
    subText,
    locationHeading,
    path,
}) {
    return (
        <div
            className="container padding-0"
            data-cmp={dataCmp}
        >
            <div className="display-flex justify-content-between align-items-center">
                <div className="display-flex flex-column">
                    {renderBreadCrumbsAndHeading(locationHeading, true, isXs, path, linkText)}
                    {subText && (
                        <Text
                            color="accent"
                            weight="bold"
                        >
                            {subText}
                        </Text>
                    )}
                </div>
                <div className="display-flex align-items-end">
                    <Image
                        src={heroSrc}
                        height={isXs ? 50 : imgHeight}
                        alt={altText}
                        width="100%"
                    />
                </div>
            </div>
        </div>
    );
}

export function SearchResultsTitleContainer() {
    const dispatch = useDispatch();

    const device = useDevice();
    const lessThanMd = _get(device, 'lessThan.md', false);
    const isXs = _get(device, 'is.xs', false);

    const { brands, isBrand } = useBrand();
    const isFordBranded = isBrand(brands.FORD_BRAND);

    const { locationHeading } = useSelector(srpContentDuck.selectors.getDuckState);

    const {
        bookmarkId = null,
        city = '',
        dma = {},
        location = {},
        searchBookmarkId = null,
        sellerType = '',
        state = '',
        zip = '',
    } = useSelector(queryDuck.selectors.getDuckState) || {};

    const savedSearch = useSelector(userDuck.selectors.getSavedSearch) || {};
    const savedSearchTitle = savedSearch.title;
    const isLoggedIn = useSelector(authDuck.selectors.isLoggedIn);

    const [keywordExpand, setKeywordExpand] = useState(false);
    const navigateToSrp = useSrpNavigation();

    const [backToResultsPath, setPath] = useState('');

    const {
        // feature configs
        enable_private_seller_exchange_logo: [enablePrivateSellerExchangeLogo],
        srp_hero: [enableSrpHero, {
            cmp: heroCmpId,
            img_height: imgHeight,
            link_text: linkText,
            sub_text: subText,
            img_alt_text: altText,
            src: heroSrc,
        }],
        personalization: [isPersonalizationEnabled] = [],
        psx_logo: [, {
            icon: privateSellerIcon,
            href: privateSellerExchangeLogoHref,
            width: privateSellerIconWidth,
        }],
        suggested_keyword: [enableKeyword],
    } = useFeatures([
        'enable_private_seller_exchange_logo',
        'srp_hero',
        'personalization',
        'psx_logo',
        'suggested_keyword',
    ]);

    useEffect(() => {
        if (searchBookmarkId && isLoggedIn) {
            dispatch(remoteActions.loadSavedSearchBookmark());
        }
        if (bookmarkId) {
            dispatch(remoteActions.handleSearchRedirect(false, navigateToSrp));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchBookmarkId]);

    // sets path for cta in SRP hero
    useEffect(() => {
        const getBackToResultsPath = async () => {
            const path = await getPathSRP({ city, dma, experience: null, location, state, zip }, { basePath: true });
            setPath(path);
        };

        if (enableSrpHero) {
            getBackToResultsPath();
        }
    }, [city, dma, enableSrpHero, location, state, zip]);

    const renderFilterChipsControls = (
        <div className="display-flex flex-nowrap">
            <SaveSearchContainer
                key="saveSearchContainer"
            />
            <InventoryClearFilters
                key="clearFilters"
                className="margin-right-3"
            />
        </div>
    );

    const handleKeywordExpand = () => {
        setKeywordExpand(!keywordExpand);
    };

    const renderKeywordBlock = (
        <>
            <div className="margin-left-2"><KeywordSearchContainer /></div>
            <div className="align-self-center">
                <Button
                    bsStyle="link"
                    onClick={() => handleKeywordExpand()}
                    className="padding-left-2"
                >
                    <Glyphicon
                        glyph="remove"
                        className="text-gray-darkest"
                    />
                </Button>
            </div>
        </>
    );

    const onlyPrivateSellerSelected = !Array.isArray(sellerType) && sellerType === 'p';

    const handlePrivateSellerExchangeLogoClick = (e) => {
        sendClick(psxPrivateSellerExchangeLogoClick, e);
    };

    return (
        <>
            <div className={clsx('container-fluid padding-top-3 padding-bottom-3', {
                'bg-blue-lightest': !isFordBranded,
            })}
            >
                {enableSrpHero && (
                    <SrpHeroContainer
                        dataCmp={heroCmpId + '-srp-hero'}
                        heroSrc={heroSrc}
                        altText={altText}
                        isXs={isXs}
                        imgHeight={imgHeight}
                        linkText={linkText}
                        subText={subText}
                        locationHeading={locationHeading}
                        path={backToResultsPath}
                    />
                )}

                <div className="container padding-0">
                    {isPersonalizationEnabled && savedSearchTitle
                        && (
                            <div className="text-gray-dark">
                                {savedSearchTitle}
                            </div>
                        )}
                    {enablePrivateSellerExchangeLogo && onlyPrivateSellerSelected && (
                        <Link
                            target="_blank"
                            href={privateSellerExchangeLogoHref}
                            onClick={handlePrivateSellerExchangeLogoClick}
                        >
                            <Image
                                src={privateSellerIcon}
                                alt="Private Seller Exchange"
                                height={27}
                                width={privateSellerIconWidth}
                                lazyload={false}
                                loading="eager"
                            />
                        </Link>
                    )}

                    {!enableSrpHero && renderBreadCrumbsAndHeading(locationHeading, false, isXs)}
                    {!enableSrpHero && !isFordBranded && (
                        <div className="display-md-flex justify-content-md-between">
                            <div className="text-accent text-bold">Buckle up! Car prices may increase, but we&apos;re here to help you navigate affordable payments.</div>
                            <Link
                                target="_blank"
                                className="link-undecorated text-bold text-size-200"
                                href="https://www.autotrader.com/tariffs"
                            >
                                Learn more about tariffs
                            </Link>
                        </div>
                    )}
                </div>
            </div>

            <div className="container padding-sm-0 margin-vertical-sm-3">
                <Row className="display-flex align-items-center justify-content-between padding-vertical-1 padding-vertical-sm-0">
                    {!isXs && (
                        <KeywordSearchContainer
                            className="col-sm-5 col-md-3"
                        />
                    )}

                    <Col
                        xs={12}
                        sm={enableKeyword ? 7 : 12}
                        md={enableKeyword ? 9 : 12}
                        className="padding-horizontal-0 padding-horizontal-sm-2"
                    >
                        <div
                            className="display-flex justify-items-center flex-direction-row w-100"
                        >
                            {enableKeyword && isXs && !keywordExpand && <KeywordButton onClick={handleKeywordExpand} />}
                            {enableKeyword && isXs && keywordExpand && renderKeywordBlock}
                            {!lessThanMd && renderFilterChipsControls}
                            {!keywordExpand && (
                                <div
                                    className="padding-top-sm-0"
                                    style={{ minWidth: '100px' }}
                                >
                                    <SuggestedFilterContainer />
                                </div>
                            )}
                        </div>
                    </Col>
                </Row>
            </div>
        </>
    );
}

export default SearchResultsTitleContainer;
