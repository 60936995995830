import { useEffect, useState } from 'react';

import { getMessaging, getToken } from 'firebase/messaging';

import { BrowserStorage } from '@atc/browser-storage';

import firebaseApp from './firebase';

const fcmTokenCache = new BrowserStorage('FCM_TOKEN', {
    persist: true,
});

const useFcmToken = () => {
    const [token, setToken] = useState('');
    const [notificationPermissionStatus, setNotificationPermissionStatus] = useState('');
    const [registration, setRegistration] = useState();

    useEffect(() => {
        const registerNotificationWorker = () => {
            if ('serviceWorker' in navigator) {
                navigator.serviceWorker.register('/cars-for-sale/api/firebase-messaging-sw.js', { scope: '/cars-for-sale/' })
                    .then((_registration) => {
                        setRegistration(_registration);
                        // eslint-disable-next-line no-console
                        console.log('[firebase-messaging-sw] - Service Worker registered with scope:', _registration.scope);
                    })
                    .catch((error) => {
                        // eslint-disable-next-line no-console
                        console.error(`[firebase-messaging-sw] - Service worker registration failed: ${error}`);
                    });
            }
        };

        registerNotificationWorker();
    }, []);

    const getFcmToken = () => getToken(getMessaging(firebaseApp), {
        serviceWorkerRegistration: registration,
        vapidKey: process.env.FCM_KEY,
    });

    const promptUser = async () => {
        // Retrieve the notification permission status
        let currentToken = '';
        const permission = await Notification.requestPermission();
        setNotificationPermissionStatus(permission);

        // Check if permission is granted before retrieving the token
        if (permission === 'granted') {
            // eslint-disable-next-line no-console
            console.log('[FCM] - Permission Granted');
            currentToken = await getFcmToken();
            // eslint-disable-next-line no-console
            console.log('[FCM] - Generated Token: ', currentToken);

            if (currentToken) {
                setToken(currentToken);
                fcmTokenCache.setItem(currentToken);
            }
        }

        return { permission, fcmToken: currentToken };
    };

    const subscribeToNotifications = async () => {
        const currentPermission = Notification.permission;
        let success = false;
        let currentToken = '';

        if (currentPermission === 'granted') {
            currentToken = await getFcmToken();

            if (currentToken) {
                setToken(currentToken);
                fcmTokenCache.setItem(currentToken);
                // eslint-disable-next-line no-console
                console.log('[FCM] - Generated Token: ', currentToken);
                success = true;
            } else {
                // eslint-disable-next-line no-console
                console.log('[FCM] - No registration token available. Request permission to generate one.');
                const { permission, fcmToken } = await promptUser();
                currentToken = fcmToken;
                success = permission === 'granted';

            }
        } else if (currentPermission === 'default') {
            // eslint-disable-next-line no-console
            console.log('[FCM] - Notifications are not enabled. Prompting user...');
            const { permission, fcmToken } = await promptUser();
            currentToken = fcmToken;
            success = permission === 'granted';
        } else {
            // eslint-disable-next-line no-console
            console.log('[FCM] - Notifications are blocked.');
            fcmTokenCache.removeItem();
        }

        return { success, fcmToken: currentToken };
    };

    return { fcmToken: token, notificationPermissionStatus, subscribeToNotifications };
};

export default useFcmToken;
