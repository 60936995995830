import { fetchJSON } from '@bonnet/next/fetch';

export default (data = {}) => {
    const { brand = 'ATC', fcmToken, pixallId, ...rest } = data;
    const options = {
        body: {
            brand,
            pixall_id: pixallId,
            browser_token: fcmToken,
            ...rest,
        },
        method: 'POST',
        circuitBreaker: {
            id: 'consumerDevice',
            timeout: 1000,
            resetTimeout: 300000,
            fallback: {},
        },
    };
    fetchJSON('/cars-for-sale/consumer-device/browser', options);
};
