import React, { memo, useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import dynamic from 'next/dynamic';

import { useDetectAdBlock } from 'adblock-detect-react';
import clsx from 'clsx';

import { getPixallId } from '@atc/pixall-fns';
import { ClientOnly } from '@atc/react-client-only';

import { useFeatures } from 'reaxl-features';
import { OwnerChat } from 'reaxl-molecules';
import { Page as PageSchema } from 'reaxl-schema';

import { getCompareListingsToggle } from '@/utilities/compareListingsHandler';
import usePaidSearchCookies from '@/utilities/usePaidSearchCookies';

import remoteActions from '@/actions/srp/remoteActions';

import {
    userAgentDuck,
    userPreferencesDuck,
} from '@/ducks';

import {
    srpActiveInteractionDuck,
    srpFiltersDuck,
    srpResultsDuck,
    srpSimilarListingsDuck,
} from '@/ducks/srp';

import MyWalletContainer from '@/containers/MyWalletContainer';
import OffsiteNotificationsPrompt from '@/containers/OffsiteNotificationsPrompt';

import LazyComponent from '@/components/LazyComponent';

import AlphaShowcaseContainer from './AlphaShowcaseContainer';
import AnalyticsWrapper from './AnalyticsWrapper';
import EditorialContentContainer from './EditorialContentContainer';
import EmailSubscribeContainer from './EmailSubscribeContainer';
import ExpiredListingRedirectAlertMessageContainer from './ExpiredListingRedirectAlertMessageContainer';
import FixedAdContainer from './FixedAdContainer';
import FixedAdWaypointContainer from './FixedAdWaypointContainer';
import FooterLeaderAdSlotContainer from './FooterLeaderAdSlotContainer';
import InventoryFiltersContainer from './InventoryFiltersContainer';
import InventorySearchResultsContainer from './InventorySearchResultsContainer';
import ModelReferenceContainer from './ModelReferenceContainer';
import SearchResultsChipContainer from './SearchResultsChipsContainer';
import SearchResultsPageModals from './SearchResultsPageModals';
import SearchResultsPageTopActionsContainer from './SearchResultsPageTopActionsContainer';
import SearchResultsRecentSearchesContainer from './SearchResultsRecentSearchesContainer';
import SearchResultsTitleContainer from './SearchResultsTitleContainer';
import SRPAlternativeSearchActionContainer from './SRPAlternativeSearchActionContainer';

// lazy load containers
const SRLTowerAdSlotContainer = dynamic(() => import(
    /* webpackChunkName: "SRLTowerAdSlotContainer" */
    './SRLTowerAdSlotContainer'
), { ssr: false });
const CarLoader = dynamic(() => import(
    /* webpackChunkName: "CarLoader" */
    'reaxl'
).then((module) => module.CarLoader), { ssr: false });
// lazy load SRP containers
const CrawlPathFooterContainer = dynamic(() => import(
    /* webpackChunkName: "CrawlPathFooterContainer" */
    '@/containers/srp/CrawlPathFooterContainer'
), { ssr: false });
const InventoryPaginationContainer = dynamic(() => import(
    /* webpackChunkName: "InventoryPaginationContainer" */
    '@/containers/srp/InventoryPaginationContainer'
), { ssr: false });
const SaveSearchContainer = dynamic(() => import(
    /* webpackChunkName: "SaveSearchContainer" */
    '@/containers/srp/SaveSearchContainer'
), { ssr: false });

const SRPBuyerConnectContainer = dynamic(() => import(
    /* webpackChunkName: "SRPBuyerConnectContainer" */
    '@/containers/srp/SRPBuyerConnectContainer'
), { ssr: false });
const SRPHeaderContainer = dynamic(() => import(
    /* webpackChunkName: "SRPHeaderContainer" */
    '@/containers/srp/SRPHeaderContainer'
), { ssr: false });

// functions
const alphaLeaderBoardContainerOnPositionChange = ({ actions, currentPosition, previousPosition }) => {
    if ((currentPosition === 'inside'
        || currentPosition === 'above')
        && previousPosition === undefined) {
        actions.hideFixedAd();
    }
    if (currentPosition === 'inside' && previousPosition === 'below') {
        actions.hideFixedAd();
    }
    // when user is at the bottom of the page and scrolls back up
    if (currentPosition === 'below' && previousPosition === 'inside') {
        actions.showFixedAd();
        actions.setRefreshCount();
    }
};

const fixedAdWayPointContainerOnPositionChange = (handlerProps) => {
    const { actions } = handlerProps;

    if (handlerProps.currentPosition === 'above' && handlerProps.previousPosition === undefined) {
        actions.showFixedAd();
    } else if (handlerProps.currentPosition === 'inside' && handlerProps.previousPosition === 'above') {
        actions.showFixedAd();
        setTimeout(() => {
            actions.refreshFixedAd('top');
        }, 1);
    }
};

const showFixedAd = (handlerProps) => {
    handlerProps.actions.showFixedAd();
};

const hideFixedAd = (handlerProps) => {
    handlerProps.actions.hideFixedAd();
};

const affixedKbbSRLTowerAdContainerRef = React.createRef();

function SearchResultsPageContainer({ currentUrl }) {
    const {
        alpha_showcase: [isAlphaEnabled],
        brand: [, { channel, inventory_image_url: inventoryImageUrl }],
        enable_still_interested_view_count: [enableStillInterestedViewCount],
        global_personalization: [isGlobalPersonalizationEnabled],
        my_wallet: [enableMyWallet],
        offsite_browser_notifications: [enableOffsiteNotiticationPrompt],
        organization_schema: [isOrganizationSchemaEnabled],
        recent_searches_new_placement: [enableNewRecentSearchesPlacement],
        schema: [, { page_schema: pageSchema } = {}],
        srp_alpha_leader_board: [enableAlphaLeaderBoard],
        srp_bottom_lazy_threshold: [, { defaultThreshold }],
        srp_email_subscribe: [enableEmailSubscribe],
        srp_expert_reviews: [enableExpertReviews],
        srp_fixed_ad_waypoint: [enableFixedAdWaypoint],
        srp_hero: [enableSrpHero],
        srp_model_reference: [enableModelReference],
        srp_paid_search_cookies: [enablePaidSearchCookies],
        standard_listing_phone_display: [displayPhoneNumber],
        standard_listing_owner_name: [hideOwnerName],
        srp_chat_engagement_test: [isSrpChatEnabled, { dealerId }],
    } = useFeatures([
        'alpha_showcase',
        'brand',
        'enable_still_interested_view_count',
        'global_personalization',
        'my_wallet',
        'offsite_browser_notifications',
        'organization_schema',
        'recent_searches_new_placement',
        'schema',
        'srp_alpha_leader_board',
        'srp_bottom_lazy_threshold',
        'srp_email_subscribe',
        'srp_expert_reviews',
        'srp_fixed_ad_waypoint',
        'srp_hero',
        'srp_model_reference',
        'srp_paid_search_cookies',
        'standard_listing_phone_display',
        'standard_listing_owner_name',
        'srp_chat_engagement_test',
    ]);
    const dispatch = useDispatch();
    const adBlockDetected = useDetectAdBlock();

    const isBot = useSelector(userAgentDuck.selectors.isBot);

    const showSpinningIndicator = useSelector(srpActiveInteractionDuck.selectors.getShowSpinningIndicator);
    const isSimilarLoading = useSelector(srpSimilarListingsDuck.selectors.getResultLoading);
    const resultCount = useSelector(srpResultsDuck.selectors.getResultsCount);
    const isFiltersVisible = useSelector(srpFiltersDuck.selectors.getFiltersVisible);

    // set kbb paid search cookies
    if (enablePaidSearchCookies) {
        // react hook rules reserve "use" prefixes
        // eslint-disable-next-line react-hooks/rules-of-hooks
        usePaidSearchCookies();
    }

    useEffect(() => {
        const pixAllId = getPixallId() || 'nl';
        if (pixAllId && pixAllId !== 'nl' && !isBot) {
            dispatch(remoteActions.getConsumerBrowsingHistoryData({
                channel,
                pixAllId,
                enableViewCount: enableStillInterestedViewCount,
                displayPhoneNumber,
                hideOwnerName,
                inventoryImageUrl,
            }));
        }

        return () => {
            dispatch(srpActiveInteractionDuck.creators.setKeys({
                showSpinningIndicator: false,
            }));
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        dispatch(userPreferencesDuck.creators.setCompareToggle(getCompareListingsToggle()));
    }, [dispatch]);

    return (
        <>
            {showSpinningIndicator && (
                <div id="page-overlay-container">
                    <CarLoader />
                </div>
            )}
            <ClientOnly>
                <SRPHeaderContainer
                    currentUrl={currentUrl}
                />
            </ClientOnly>

            {enableMyWallet && (
                <MyWalletContainer
                    blockTradeInFetch
                />
            )}

            {enableOffsiteNotiticationPrompt && (
                <OffsiteNotificationsPrompt />
            )}

            <main aria-labelledby="search-results-heading">
                {(!enableNewRecentSearchesPlacement && !enableSrpHero) && <SearchResultsRecentSearchesContainer />}
                <SearchResultsTitleContainer />

                <div className="container padding-sm-0">
                    <div className="row margin-horizontal-0 padding-horizontal-0">
                        <FixedAdWaypointContainer
                            cmp="leaderboard"
                            onEnter={showFixedAd}
                            onPositionChange={fixedAdWayPointContainerOnPositionChange}
                        />

                        <div
                            className="row display-sm-flex"
                            ref={affixedKbbSRLTowerAdContainerRef}
                        >

                            <div className="col-md-3 display-none display-md-block">
                                <InventoryFiltersContainer />
                                <LazyComponent>
                                    <SRLTowerAdSlotContainer affixedRef={affixedKbbSRLTowerAdContainerRef} />
                                </LazyComponent>
                            </div>

                            <div
                                className={clsx('col-xs-12 col-md-9', { invisible: isFiltersVisible })}
                                id="srp-listings"
                            >

                                {(enableEmailSubscribe || isGlobalPersonalizationEnabled)
                                    && <EmailSubscribeContainer />}

                                <SearchResultsPageTopActionsContainer />

                                <ExpiredListingRedirectAlertMessageContainer />

                                <SearchResultsChipContainer />

                                {isAlphaEnabled && <AlphaShowcaseContainer />}

                                {isOrganizationSchemaEnabled && <PageSchema pageSchemaData={pageSchema} />}

                                <InventorySearchResultsContainer />

                                <SearchResultsPageModals />

                                {enableAlphaLeaderBoard && (
                                    <FixedAdWaypointContainer
                                        cmp="alphaLeaderboard"
                                        onPositionChange={alphaLeaderBoardContainerOnPositionChange}
                                    />
                                )}

                                {enableExpertReviews
                                    && (
                                        <LazyComponent>
                                            <EditorialContentContainer />
                                        </LazyComponent>
                                    )}

                                <SRPAlternativeSearchActionContainer />

                                {!isSimilarLoading && (
                                    <LazyComponent
                                        height={{ xs: 246, sm: 191, md: 191, lg: 124 }}
                                        threshold={defaultThreshold}
                                    >
                                        <SaveSearchContainer isEmailCapture />
                                    </LazyComponent>
                                )}

                                <LazyComponent
                                    height={{ xs: 146, sm: 117, md: 117, lg: 117 }}
                                    threshold={defaultThreshold}
                                >
                                    <InventoryPaginationContainer />
                                </LazyComponent>

                                {resultCount < 9 && (
                                    <LazyComponent
                                        height={{ xs: 378, sm: 285, md: 285, lg: 285 }}
                                        threshold={defaultThreshold}
                                    >
                                        <SRPBuyerConnectContainer position="below" />
                                    </LazyComponent>
                                )}

                                <FooterLeaderAdSlotContainer />
                            </div>
                        </div>
                    </div>

                    {!adBlockDetected && (<FixedAdContainer />)}
                    <AnalyticsWrapper />

                    <hr className="margin-top-5" />
                </div>

                {enableModelReference
                    && (
                        <ClientOnly fallback={(<div style={{ height: 850 }} />)}>
                            <ModelReferenceContainer />
                        </ClientOnly>
                    )}

                <div className="container">
                    <ClientOnly>
                        <CrawlPathFooterContainer />
                    </ClientOnly>

                    {enableFixedAdWaypoint && (
                        <FixedAdWaypointContainer
                            cmp="leaderboard"
                            onEnter={hideFixedAd}
                            onLeave={showFixedAd}
                        />
                    )}
                </div>

            </main>

            {isSrpChatEnabled && (
                <ClientOnly>
                    <OwnerChat
                        provider="ACTIVENGAGE"
                        showChatCTA={false}
                        merchantId={dealerId}
                    />
                </ClientOnly>
            )}
        </>
    );
}

export default memo(SearchResultsPageContainer);
