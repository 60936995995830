import React, { useRef } from 'react';

import { useCookies } from 'react-cookie';
import { useDispatch, useSelector } from 'react-redux';

import { IconButton } from 'reaxl';
import { sendClick } from 'reaxl-analytics';
import { myWalletIconButtonClick } from 'reaxl-analytics-handlers';
import { useFeatures } from 'reaxl-features';
import { WalletPopover } from 'reaxl-wallet';

import { paymentsDuck, savedInventoryDuck } from '@/ducks';

const SUPPRESS_ONE_TAP_COOKIE = 'caiSuppressGoogleOneTap';

function MyWalletIconButton() {
    const {
        payments_popover: [enablePaymentsPopover],
    } = useFeatures([
        'payments_popover',
    ]);

    const dispatch = useDispatch();

    const targetRef = useRef();

    const [cookies] = useCookies([SUPPRESS_ONE_TAP_COOKIE]);
    const isOneTapSuppressed = cookies && cookies[SUPPRESS_ONE_TAP_COOKIE];

    const savedListingIds = useSelector(savedInventoryDuck.selectors.getSavedInventoryIds) || [];
    const disableComparePopover = useSelector((state) => paymentsDuck.selectors.getMyWalletInteraction(state, 'disableComparePopover'));

    const showPaymentsPopover = (enablePaymentsPopover && !disableComparePopover && savedListingIds.length < 1 && isOneTapSuppressed)
        || disableComparePopover;

    const openWalletModal = () => {
        sendClick(myWalletIconButtonClick);

        dispatch(paymentsDuck.creators.updateMyWalletInteractions({
            paymentEmphasis: true,
            disablePaymentPopover: true,
        }));

        dispatch(paymentsDuck.creators.updateMyWallet({ displayModal: true }));
    };

    return (
        <>
            <div
                data-cmp="wallet-iconbutton"
                ref={targetRef}
            >
                <IconButton
                    glyph="wallet"
                    label="My Wallet"
                    onClick={openWalletModal}
                />
            </div>
            <WalletPopover
                ref={targetRef}
                paymentsDuck={paymentsDuck}
                withPopover={showPaymentsPopover}
            />
        </>
    );
}

export default MyWalletIconButton;
